.tick .label {
    font-family: $font-aktivgroteskcd;
    font-size: 14px;
}

.chart__label_total {
    font-family: $font-aktivgroteskcd;
    font-size: 16px;
    color: color("gris_eres");
}

.chart__tooltip,
.chart-tooltip {
    position: absolute;
    background-color: color("gris_eres");
    color: color("white");
    border-radius: 5px;
    padding: 10px 20px;
    z-index: 10;

    &__title {
        font-weight: bold;
    }

    &__list {
        padding: 0;
        margin: 0;
    }

    &__item {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
        + & {
            margin-top: 2000;
        }
    }

    &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: color("gris_eres");
    }
}

.chart__legend-text {
    font-family: $font-aktivgroteskcd;
    font-size: 12px;
}