$path-font: '~eres-integration/src/fonts/';
$path-img: '~eres-integration/src/images/';
@import '~eres-integration/src/scss/main.scss';
@import 'graph.scss';

.container {
    max-width: 100%;
    margin: 0 auto;

    &__large {
        @include media-breakpoint-up("small") {
            width: 904px,
        }

        @include media-breakpoint-up("large") {
            width: 1180px,
        }
    }
}

.progress {
    transform: rotate(-90deg);
}

.d-flex {
    display: flex;

    &-space-between{
        justify-content: space-between;
    }

    &-align-items-center{
        align-items: center;
    }
}

.align-self--bottom {
    align-self: flex-end;
}

.align-self--center {
    align-self: center;
}

.container__form--little {
    margin: auto;
    width: 640px;
    max-width: 100%;
}

.container__form--low--medium {
    margin: auto;
    width: 740px;
    max-width: 100%;
}

.container__form--medium {
    margin: auto;
    width: 940px;
    max-width: 100%;
}

.bordered {
    border: 1px solid color("bleu_ciel_5");
}

.paper__border {
    border: 1px solid color("bleu_ciel_5");
}

.ceiling {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &__icon {
        color: color("bleu_lumiere");
    }

    &__header {
        font-weight: bold;
        font-size: 20px;

        .ceiling__header__cell{
            font-size: 16px;
        }
    }
}

.paper_button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    @include media-breakpoint-up("medium") {
        margin-left: 40px;
    }
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
}

.button_fixed_list {
    @include media-breakpoint-down("xsmall") {
        position: fixed;
        right: 10px;
        bottom: 20px;
        z-index: 100;
    }
}

.table__col {
    &--overflow {
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;

        @include media-breakpoint-down("medium") {
            max-width: 150px;
        }
    }
}

.table-overflow-wrapper {
    @include media-breakpoint-down("xsmall") {
        margin-bottom: 90px;
    }
}

.autocomplete-field {
    position: relative;

    &-list {
        position: absolute;
        top: calc(100% - 10px);
        left: 0;
        width: 100%;
        padding: 8px 0;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        box-shadow: 8px 6px 16px rgba(0, 0, 0, 0.1);
        z-index: 1;
        list-style: none;

        > li {
            padding: 7px 15px;
            cursor: pointer;
            transition: 0.2s all;

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }

            &.selected {
                background: rgba(55, 63, 76, 0.4);
            }
        }
    }
}

.flash-message {
    &__container {
        @include media-breakpoint-up("medium") {
            top: $topbar-height;
            right: calc(50% - #{$sidebar-min-width});
            transform: translateX(calc(50% - (#{$sidebar-min-width} / 2)));
            width: 40%;
            padding-top: 10px;
        }
    }
}

.chart-legend {
    font-size: 12px;

    .legend-shape {
        display: inline-block;
        margin-right: 5px;
    }
}

.btn_download_disabled {
    background-color: color("white") !important;
    border: none;
}

.alert-badge {
    position: relative;

    &__content {
        background-color: color("rouge");
        color: color("white");
        padding: 1.5px 3px;
        border-radius: 50%;
        font-size: 10px;
        position: absolute;
        top: -12px;
        left: -10px;
    }
}

.field-group__adornment {
    >.container-tooltip {
        white-space: normal;
    }
}

.steps.grid--3{
	text-align: center;
}
.steps{
    padding:0 40px;
    position: relative;
}
.steps .number{
	border-radius: 25px;
	margin: 0 auto 5px;
	color:white;
	height: 50px;
	width:50px;
	position:relative;
}
.steps .number img{
	width:30px;
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.steps::before {
    content: "";
    position: absolute;
    width: 66.666%;
    height: 1px;
    border-top: 2px dashed #78bce9;
    position: absolute;
    left: 16.6666%;
    margin-top: 25px;
    z-index: 0;
}

.bg--bleu_clair_2{
	background : #49a8e2;
}
.bg--bleu_clair_3{
	background : #78bce9;
}

.paper .situation > div:last-child {
	border-left : 3px solid color("bleu_lumiere");
}

.paper .situation > div:last-child:before {
	content:" ";
	position: relative;
	margin-left: -6px;
	margin-top:20px;
	width:10px;
	height:10px;
	transform: rotate(45deg);
	border-top:3px solid color("bleu_lumiere");
	border-right:3px solid color("bleu_lumiere");
    background-color: white;
    display: block;
}

.sidebar__nav{
    overflow: visible !important
}
.sidebar {
        z-index: 10 !important;
}